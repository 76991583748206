import React from "react";
import { graphql } from "gatsby";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import { differenceInDays, formatDistance, format } from "date-fns";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import PortableText from "../components/Blog/portableText";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
  query PodcastTemplateQuery($id: String!) {
    podcast: sanityPodcast(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      seoTitle
      metaDescription
      excerpt
      _rawBody(resolveReferences: { maxDepth: 5 })
    }
  }
`;

const PodcastTemplate = (props) => {
  const { data, errors, next, prev, id } = props;
  const podcast = data && data.podcast;
  return (
    <Layout>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {podcast && (
        <SearchEngineOptimization
          title={podcast.seoTitle}
          description={podcast.metaDescription}
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {podcast && (
        <>
          {id}
          <div className="container">
            <article className="pt-12 md:pt-20 pb-16 md:pb-24 mb-20 md:mb-32 bg-white px-6 md:px-14 lg:px-22">
              <header className="mb-10 md:mb-18 md:text-center">
                <h1 className="text-gray-800 heading-two">{podcast.title}</h1>
              </header>

              <div className="flex justify-between items-end mb-8 md:mb-10">
                {podcast.publishedAt && (
                  <div className="text-sm text-gray-900 font-normal">
                    {differenceInDays(
                      new Date(podcast.publishedAt),
                      new Date()
                    ) > 3
                      ? formatDistance(
                          new Date(podcast.publishedAt),
                          new Date()
                        )
                      : format(new Date(podcast.publishedAt), "MMMM d, yyyy")}
                  </div>
                )}
              </div>

              <div className="mb-8">
                {podcast._rawBody && <PortableText blocks={podcast._rawBody} />}
              </div>
              <p className="text-gray-800 mb-0">{podcast.excerpt}</p>

              <div className="flex justify-between mt-16">
                {prev ? (
                  <AniLink
                    fade
                    to={`/podcast/${prev.slug.current}/`}
                    className="group font-bold inline-flex items-center justify-center space-x-2.5 text-primary-600 hover:text-primary-600"
                  >
                    <i className="far fa-arrow-left text-xl relative right-0 group-hover:right-1.5 transition-all duration-300 ease-linear"></i>{" "}
                    <span>Previous</span>
                  </AniLink>
                ) : (
                  <div></div>
                )}

                {next ? (
                  <AniLink
                    fade
                    to={`/podcast/${next.slug.current}/`}
                    className="group font-bold inline-flex items-center justify-center space-x-2.5 text-primary-600 hover:text-primary-600"
                  >
                    <span>Next</span>
                    <i className="far fa-arrow-right text-xl relative left-0 group-hover:left-1.5 transition-all duration-300 ease-linear"></i>
                  </AniLink>
                ) : (
                  <div></div>
                )}
              </div>
            </article>
          </div>
          <CallToAction />
        </>
      )}
    </Layout>
  );
};

export default PodcastTemplate;
